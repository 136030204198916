import React from 'react';
import CtaBlock from '../../components/components-group-one/CtaBlock';
import Footer from '../../components/footer';
import HtmlHead from '../../components/html-head';
import Navbar from '../../components/navbar';
import { PageData } from '../../types';
import ResourceHero from '../../components/resources-components/resource-hero';
import CaseStudy from '../../components/resources-components/case-study';
import cority from '../../assets/resources/cority.png';
import chris from '../../assets/resources/Chris slaugh.png';
import coritypdf from '../../assets/home-v3/Cority.pdf';
import { graphql } from 'gatsby';

const Cority: React.FC<PageData> = resp => {
    const seoData = resp?.data?.allContentfulPageSeoData?.edges[0]?.node;

    const resourceData = {
        formName : 'cority',
        companyLogo: cority,
        title: 'Enhancing Agent Time <br/> to Productivity',
        author: {
            image: chris,
            name: '<span>Chris</span> Slaugh',
            title: 'Former VP, Global Customer Support',
            quote: `Now our brand new analysts come on board and they hit that time to productivity number within two months. So
                    that's almost 70% improvement in our time to productivity.`
        },
        stats: [
            {
                percentage: '75%',
                title: 'Improvement to Analysis Time'
            },
            {
                percentage: '67%',
                title: 'Improvement to Time to Productivity'
            },
            {
                percentage: '12%',
                title: 'Improvement to Resolution Time'
            }
        ],
        pdf: coritypdf,
        ctaTitle: "Download PDF",
        count: [
            {
                num: '1,600+',
                title: 'global customers'
            },
            {
                num: '120+',
                title: 'countries'
            },
            {
                num: '2M+',
                title: 'end users'
            },
            {
                num: '600+',
                title: 'employees'
            }
        ],
        countColor: '#E75500',
        isdownload: true
    };

    const caseStudy = [
        {
            title: 'About',
            desc: `Cority provides a comprehensive suite of software solutions aimed at enhancing environmental, 
                    health, safety (EHS), and sustainability management within organizations. Their platform, known
                     as CorityOne, is a human-centered enterprise EHS Software-as-a-Service (SaaS) platform designed 
                     by industry experts. The primary goal of CorityOne is to empower organizations to become healthier, 
                     safer, and more sustainable, ultimately contributing to the construction of better enterprises 
                     for the future. The platform integrates various facets of EHS management, offering a unified 
                     experience and leveraging advanced analytics and expert-driven insights to enhance organizational performance.`
        },
        {
            title: 'Solution',
            desc: `Cority chose AptEdge for its ability to enhance agent productivity and success. Key factors in this decision included:
                    <br /><br />
                    <span>Integration with Existing Systems:</span> AptEdge's ability to federate across various platforms like Confluence, JIRA, and Salesforce without additional connector costs.
                    <br /><br />
                    <span>Training and Onboarding:</span> AptEdge provided hands-on training directly to analysts and team leads, ensuring higher adoption rates.
                    <br /><br />
                    <span>AI-Powered Support:</span> Leveraging AI for data visualization, reporting, and proactive customer frustration identification.`
        },
        {
            title: 'Challenge',
            desc: `<span>Agent Productivity:</span> <br />
                    Agent productivity, particularly with new agents, in terms of time to productivity. Their target was to reduce
                    the time taken to hit case closure targets.
                    <br /><br />
                    <span>Resolution Times:</span> <br />
                    Improving resolution times for customer queries was a major concern.
                    <br /><br />
                    <span>Agent Training and Onboarding:</span> <br />
                    Efficiently onboarding new agents and ramping up their productivity was challenging, given the complexity of
                    Cority's platform.`
        },
        {
            title: 'Results',
            desc: `<span>Improved Time to Productivity:</span> This metric was reduced from six months to two months, a 67% improvement.
                    <br /><br />
                    <span>Enhanced Resolution Times:</span> A 12% improvement in overall resolution times for customer issues.
                    <br /><br />
                    <span>Search Efficiency:</span> Up to 75% improvement in search times for analysts.
                    <br /><br />
                    <span>Cost Savings:</span> Significant cost reductions due to not requiring additional connectors for various data sources.
                    <br /><br />
                    <span>Agent Experience:</span> Improved agent experience and efficiency, leading to better customer service.
                    <br /><br />
                    <span>AI Utilization:</span> Effective use of AI in linking common issues and proactive problem-solving.`
        },
    ];

    return (
        <>
            <HtmlHead
                title={seoData?.title}
                metaDescription={seoData?.metaDescription?.metaDescription}
                canonicalLink={seoData?.canonicalLink}
                openGraphImage={seoData?.openGraphImage?.file?.url}
            />
            <Navbar />
            <ResourceHero data={resourceData} />
            <CaseStudy data={caseStudy} />
            <CtaBlock ctaTitle="Start Today!" />
            <Footer />
        </>
    );
};

export default Cority;

export const query = graphql`
    query CorityQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/case-study/cority" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;